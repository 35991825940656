<!-- 营销管理 实体充电卡 充电卡管理 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        :title="tableTitle"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <ykc-button type="plain" v-rbac="'marketing:card:charge:import'" @click="batchAdd">
              批量录入
            </ykc-button>
            <ykc-button type="plain" v-rbac="'marketing:card:charge:add'" @click="doAdd">
              录入
            </ykc-button>
            <ykc-button type="plain" v-rbac="'marketing:card:charge:export'" @click="doExport">
              导出
            </ykc-button>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <!-- 作废理由弹窗 -->
    <ykc-operation-dialog
      destroy-on-close
      :hasContentBorder="true"
      :show.sync="showReasonDialog"
      title="作废理由"
      size="medium"
      :show-footer="false">
      <template slot="content">
        <div class="dialog-operation-reason">
          {{ reasonContent }}
        </div>
      </template>
    </ykc-operation-dialog>
    <!-- 作废弹窗 -->
    <CancelDialog
      :currentCancelData="currentCancelData"
      :showCancelDialog="showCancelDialog"
      @close="showCancelDialog = false"
      @finish="searchTableList" />
    <!-- 新增编辑 -->
    <AddOrEdit
      :showAddDialog="showAddDialog"
      :currentData="currentData"
      @close="showAddDialog = false"
      @finish="searchTableList" />
    <!-- 批量导入 -->
    <ykc-operation-dialog
      destroy-on-close
      :show.sync="batchAddDialog"
      title="批量导入"
      size="medium"
      :on-close="onCloseBatchAdd"
      :before-cancel="beforeCancelBatchAdd"
      :before-confirm="beforeConfirmBatchAdd">
      <template slot="content">
        <ykc-form :model="batchAddForm" :rules="batchAddRules" ref="batchAddForm">
          <ykc-form-item class="file-error" label="选择文件" prop="file">
            <ykc-upload
              type="xlsx"
              :size="5"
              ref="ykcupload"
              :checkInfo="{ typeMess: '文件格式错误，请重新导入', sizeMess: '文件大小不超过' }"
              @handleChange="uploadFile"
              @handleRemove="handleRemove"
              temText="充电卡批量导入模板"
              :temhref="temhref"></ykc-upload>
          </ykc-form-item>
        </ykc-form>
      </template>
    </ykc-operation-dialog>
  </scroll-layout>
</template>

<script>
  import CancelDialog from './CancelDialog.vue';
  import AddOrEdit from './AddOrEdit.vue';

  import { code, offlineExport } from '@/utils';
  import { chargingCardManage } from '@/service/apis';

  console.log(process.env);

  export default {
    name: 'chargingCardManageList',
    components: {
      CancelDialog,
      AddOrEdit,
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'cardNo',
            label: '卡面卡号',
            placeholder: '请输入卡面卡号',
          },
          {
            comName: 'YkcInput',
            key: 'physicalCardNo',
            label: '物理卡号',
            placeholder: '请输入物理卡号',
          },
          {
            comName: 'YkcDropdown',
            key: 'status',
            label: '状态',
            placeholder: '请选择状态',
            data: [
              {
                id: '1',
                name: '已绑定',
              },
              {
                id: '2',
                name: '未绑定',
              },
              {
                id: '3',
                name: '已作废',
              },
            ],
          },
          {
            comName: 'YkcInput',
            key: 'userAccount',
            label: '绑定用户',
            placeholder: '请输入绑定用户',
          },
        ];
      },
    },
    watch: {
      // 关闭弹窗，当前操作数据需要清空，以防下次使用老数据
      showAddDialog: 'resetCurrentData',
    },
    data() {
      return {
        temhref: `${process.env.VUE_APP_OSS}/marketing/uploadFile/%E5%85%85%E7%94%B5%E5%8D%A1%E6%89%B9%E9%87%8F%E5%AF%BC%E5%85%A5.xls`,
        batchAddForm: {
          file: [],
        },
        batchAddRules: {
          file: [{ required: true, message: '请上传文件', trigger: 'change' }],
        },
        batchAddDialog: false, // 批量导入弹窗
        showAddDialog: false, // 新增编辑弹窗
        currentData: null,
        showReasonDialog: false, // 作废理由弹窗
        reasonContent: '',
        drawerVisible: false, // 新增抽屉
        currentCancelData: null,
        showCancelDialog: false, // 作废理由弹窗
        ruleForm: {},
        showCwd: false,
        tableData: [],
        tableColumns: [
          { label: '卡面卡号', prop: 'cardNo', minWidth: '160px' },
          { label: '物理卡号', prop: 'physicalCardNo', minWidth: '140px' },
          {
            label: '充电卡状态',
            prop: 'status',
            minWidth: '140px',
            scopedSlots: {
              default: ({ row }) => {
                const statusMap = {
                  1: '已绑定',
                  2: '未绑定',
                  3: '已作废',
                };
                const status = statusMap[row.status] || '——';
                return (
                  <div style="display:flex;align-items:center">
                    <span title={status}>{status}</span>
                    <span
                      class="status-icon"
                      style={[row.status === '3' && row.reason ? {} : { display: 'none' }]}
                      onClick={() => this.openReason(row)}></span>
                  </div>
                );
              },
            },
          },
          {
            label: '绑定用户',
            prop: 'userAccount',
            minWidth: '80px',
          },
          {
            label: '修改时间',
            prop: 'modifiedTime',
            minWidth: '80px',
          },
        ],
        tableTitle: '充电卡列表',

        tableOperateButtons: [
          {
            enabled: () => code('marketing:card:charge:edit'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status !== '2'}
                onClick={() => {
                  this.currentData = {
                    physicalCardNo: row.physicalCardNo,
                    cardNo: row.cardNo,
                    id: row.id,
                  };
                  this.showAddDialog = true;
                }}>
                编辑
              </ykc-button>
            ),
          },
          {
            enabled: () => code('marketing:card:charge:cancel'),
            render: (h, data, { row }) => (
              <ykc-button
                type="text"
                disabled={row.status !== '2'}
                onClick={() => {
                  this.openCancel(row);
                }}>
                作废
              </ykc-button>
            ),
          },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          cardNo: '',
          physicalCardNo: '',
          status: '',
          userAccount: '',
        },
      };
    },
    mounted() {
      this.searchTableList();
    },
    methods: {
      // 重置编辑信息
      resetCurrentData(val) {
        if (!val) {
          this.currentData = null;
        }
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          size: this.pageInfo.pageSize,
          current: this.pageInfo.pageIndex,
        };
        chargingCardManage
          .chargeQueryForPage(reqParams)
          .then(res => {
            console.log('res', res);
            this.tableData = res?.records || [];
            this.pageInfo.total = res?.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },
      /** 点击查询按钮 */
      handleSearchConfirm(form) {
        this.pageInfo.pageIndex = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
      // 批量录入
      batchAdd() {
        this.batchAddDialog = true;
      },
      // 录入
      doAdd() {
        this.showAddDialog = true;
      },
      // 导出
      doExport() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'charging_card_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      // 作废理由
      openCancel(row) {
        this.currentCancelData = {
          physicalCardNo: row.physicalCardNo,
          cardNo: row.cardNo,
          reason: '',
          id: row.id,
        };
        this.showCancelDialog = true;
      },
      openReason(row) {
        this.showReasonDialog = true;
        this.reasonContent = row.reason;
      },
      onCloseBatchAdd() {
        this.batchAddForm.file = [];
        this.batchAddDialog = false;
      },
      beforeCancelBatchAdd(done) {
        this.batchAddForm.file = [];
        done();
      },
      beforeConfirmBatchAdd(done) {
        this.$refs.batchAddForm.validate(async valid => {
          console.log(valid);
          if (valid) {
            const formData = new FormData();
            const param = {
              file: this.batchAddForm.file.raw,
              bizName: '',
            };
            console.log('param', param);
            // 处理入参格式
            Object.entries(param).forEach(([k, v]) => {
              formData.append(k, v);
            });
            chargingCardManage.chargeBatchImport(formData).then(() => {
              this.searchTableList();
              done();
            });
            this.$refs.batchAddForm.clearValidate();
          }
        });
      },
      uploadFile(file, fileList, isCheck) {
        console.log(file, fileList, isCheck);
        if (isCheck) {
          this.batchAddForm.file = file;
          this.$refs.batchAddForm.clearValidate();
        }
      },
      handleRemove() {
        this.batchAddForm.file = [];
      },
    },
  };
</script>
<style lang="scss" scoped>
  .dialog-operation-reason {
    padding: 7px 15px 7px 7px;
    white-space: pre-wrap;
    box-sizing: border-box;
    max-height: 450px;
  }
  .button-wrap {
    button {
      margin-left: 8px;
    }
  }
  .drawer-button-wrap {
    padding: 0 10px;
    button {
      margin-right: 5px;
    }
  }

  .status-icon {
    margin-left: 4px;
    cursor: pointer;
    width: 10px;
    height: 10px;
    background: no-repeat url('~@/assets/images/status-icon.png');
    background-size: 100% 100%;
  }
</style>
