<template>
  <ykc-operation-dialog
    title="作废"
    :on-close="onCloseIncrease"
    :show.sync="showDialog"
    :before-cancel="beforeCancelIncrease"
    :before-confirm="beforeConfirmIncrease">
    <div slot="content">
      <ykc-form :model="cancelForm" :rules="cancelRules" ref="cancelForm">
        <ykc-form-item label="物理卡号">{{ cancelForm.physicalCardNo }}</ykc-form-item>
        <ykc-form-item label="卡面卡号">{{ cancelForm.cardNo }}</ykc-form-item>
        <ykc-form-item label="作废理由" prop="reason">
          <ykc-input
            type="textarea"
            placeholder="请输入作废理由"
            maxlength="500"
            v-model="cancelForm.reason"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-operation-dialog>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { chargingCardManage } from '@/service/apis';

  export default {
    props: ['currentCancelData', 'showCancelDialog'],
    data() {
      return {
        showDialog: false,
        cancelForm: {
          physicalCardNo: '',
          cardNo: '',
          reason: '',
          id: '',
        },
        cancelRules: {
          reason: [
            {
              pattern: regexpObj.regexp.input.textAreaType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.textAreaType.errorTips.error('作废理由'),
            },
          ],
        },
      };
    },
    watch: {
      currentCancelData(val) {
        this.cancelForm = { ...val };
      },
      showCancelDialog(val) {
        if (val) {
          this.showDialog = true;
        }
      },
    },
    methods: {
      // 作废理由 取消
      beforeCancelIncrease(done) {
        this.$refs.cancelForm.clearValidate();
        this.$emit('close');
        done();
      },
      // 作废理由 确定
      beforeConfirmIncrease(done) {
        this.$refs.cancelForm.validate(async valid => {
          if (valid) {
            chargingCardManage.chargeCancel({ ...this.cancelForm }).then(res => {
              console.log(res);
              this.$emit('close');
              this.$emit('finish');
              this.$message({
                message: '作废成功',
                type: 'success',
              });
              done();
            });
          }
        });
      },
      // 关闭 作废理由弹窗
      onCloseIncrease() {
        this.$refs.cancelForm.clearValidate();
        this.$emit('close');
        this.showDialog = false;
      },
    },
  };
</script>
