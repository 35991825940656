<template>
  <ykc-operation-dialog
    destroy-on-close
    :title="title"
    :on-close="onCloseIncrease"
    :show.sync="showDialog"
    :before-cancel="beforeCancelIncrease"
    :before-confirm="beforeConfirmIncrease">
    <div slot="content">
      <ykc-warm-tip
        multi-line
        description="温馨提示：请使用正确的读卡器读取物理卡号，否则可能会导致充电卡无法充电"></ykc-warm-tip>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm">
        <ykc-form-item label="物理卡号" prop="physicalCardNo">
          <ykc-input
            placeholder="请输入物理卡号"
            maxlength="30"
            v-model="ruleForm.physicalCardNo"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="卡面卡号" prop="cardNo">
          <ykc-input
            placeholder="请输入卡面卡号"
            maxlength="30"
            v-model="ruleForm.cardNo"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-operation-dialog>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { chargingCardManage } from '@/service/apis';

  export default {
    props: ['currentData', 'showAddDialog'],
    data() {
      return {
        title: '充电卡',
        showDialog: false,
        ruleForm: {
          physicalCardNo: '',
          cardNo: '',
          id: '',
        },
        rules: {
          physicalCardNo: [
            { required: true, message: '请输入物理卡号', trigger: 'blur' },
            {
              required: true,
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('物理卡号'),
            },
          ],
          cardNo: [
            { required: true, message: '请输入卡面卡号', trigger: 'blur' },
            {
              required: true,
              pattern: regexpObj.regexp.input.accountType.regexp,
              trigger: 'blur',
              message: regexpObj.regexp.input.accountType.errorTips.error('卡面卡号'),
            },
          ],
        },
      };
    },
    watch: {
      showAddDialog(val) {
        if (val) {
          this.handleOpen();
        }
      },
    },
    methods: {
      handleOpen() {
        this.showDialog = true;
        this.ruleForm.physicalCardNo = '';
        this.ruleForm.cardNo = '';
        this.ruleForm.id = '';
        if (this.currentData) {
          this.title = '充电卡编辑';
          Object.keys(this.ruleForm).forEach(key => {
            this.ruleForm[key] = this.currentData[key];
          });
        } else {
          this.title = '充电卡录入';
        }
      },
      // 取消
      beforeCancelIncrease(done) {
        this.$refs.ruleForm.clearValidate();
        this.$emit('close');
        done();
      },
      // 确定
      beforeConfirmIncrease(done) {
        this.$refs.ruleForm.validate(async valid => {
          console.log(valid);
          if (valid) {
            if (this.ruleForm.id) {
              chargingCardManage.chargeEdit({ ...this.ruleForm }).then(res => {
                console.log(res);
                this.$emit('close');
                this.$emit('finish');
                done();
              });
            } else {
              chargingCardManage.chargeAdd({ ...this.ruleForm }).then(res => {
                console.log(res);
                this.$emit('close');
                this.$emit('finish');
                done();
              });
            }
          }
        });
      },
      // 关闭弹窗
      onCloseIncrease() {
        this.$refs.ruleForm.clearValidate();
        this.$emit('close');
        this.showDialog = false;
      },
    },
  };
</script>
